const weekdays = [
  {
    id: 1,
    name: 'понедельник'
  },{
    id: 2,
    name: 'вторник'
  },{
    id: 3,
    name: 'среда'
  },{
    id: 4,
    name: 'четверг'
  },{
    id: 5,
    name: 'пятница'
  },{
    id: 6,
    name: 'суббота'
  },{
    id: 7,
    name: 'воскресенье'
  },
];
export default weekdays;