const emptyMealsForm = {
  id:0, 
  article: '', 
  name: '', 
  time: [], 
  weekday: [],
  type: {name: '', id: 0}, 
  diet: [],
  daysInWeek: {
    'понедельник': 0,
    'вторник': 0,
    'среда': 0,
    'четверг': 0,
    'пятница': 0,
    'суббота': 0,
    'воскресенье': 0,
  },
  proteins: 0,
  fats: 0,
  carbs: 0,
  caloric: 0,
};
export default emptyMealsForm;