import {createTheme} from '@material-ui/core/styles';

const light = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#7aa329',
      contrastText: '#fff',
      text: '#000'
    },
    secondary: {
      main: '#840000',
      contrastText: '#fff',
      text: '#000'
    },
    text: {
      primary: '#010',
    },
    backgroundColor: '#fff',
    backgroundColorTransparent:'#efefef99',
  },
});


const dark = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#99CC33',
      contrastText: '#000',
      text: '#aaa'
    },
    secondary: {
      main: '#e87575',
      contrastText: '#000',
    },
    text: {
      primary: '#beb',
    },
    backgroundColor: '#000',
    backgroundColorTransparent:'#222e',
  },
});

export {light, dark};