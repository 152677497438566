import styled from 'styled-components';
import { css } from 'styled-components';

const BlockWrap = styled.div`
  position: relative;
  display: block;
  ${({ pageWidth }) => {
    if (pageWidth) {
      return css`       
        width: ${pageWidth+16}px;
      `;
    } else {
      return css`
        width: 568px;
      `;
    }
  }}
`;

export default BlockWrap;