import styled, { css } from 'styled-components';

const Paragraph = styled.p`
  ${({ theme }) => {
    if (theme) {
      return css`       
        color: ${(theme==='light')?'#000':'#fff'},
      `;
    } else {
      return css`
        color: '#888'
      `;
    }
  }}
`;

export default Paragraph;