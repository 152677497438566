import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Top from '../../components/Top';
import TopAnswer from '../../components/TopAnswer';
import BottomShare from '../../components/BottomShare';
import InputForm from '../../components/InputForm';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import moment from 'moment';
import 'moment/locale/ru';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import Logo from '../../assets/logo';
import {lay} from '../../constants/Layout';
import { Height, SettingsRemoteOutlined } from '@material-ui/icons';



import {Context} from '../../functions/context';



export default function Log(props) {

  const context = useContext(Context);
  const classes = useStyles();
  const [role, setRole] = useState('');

  const accountsObj = {};

  context.accounts.map((item, index)=>{
    accountsObj[item.id]=item;
  });

  const today = moment(+new Date()).format('ll');
  return (
    
    <>
      <Top/>
      
      <Container
      //className={classes.root}
        style={{
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'centre',
          justifyContent:'flex-start',
          paddingTop:  80,
          height: lay.window.height-80,
          transition: '1s',
          
          backgroundColor: (context.theme === 'light')?'#fff':'#000',
        }}
      >
        {/*   <h4
          style={{
                color: (context.theme==='light') ? '#224':'#bbf',
                }}>{today}</h4> */}
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">роль</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={role}
            onChange={(e)=>{
              setRole(e.target.value);
            }}
            label="роль"
          >
            <MenuItem value={''}>{'все'}</MenuItem>
            {context.roles.map((item, index)=>
              <MenuItem key={index} value={item}>{item}</MenuItem>
            )
            }
          </Select>
        </FormControl>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: lay.window.width-27,
            maxWidth: 600,
            height: 40,
            backgroundColor: (context.theme === 'light')?'#ccc':'#333',
            color: (context.theme === 'light')?'#000':'#fff',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 4,
            margin: 4,
            padding: 4,
          }}>
          <b
            style={{color: (context.theme === 'light')?'#000':'#fff', width: 100, marginRight: 4}}
          >время</b>
          <b
            style={{color: (context.theme === 'light')?'#000':'#fff', flex: 1, marginRight: 4}}
          >действие</b>
          <b
            style={{color: (context.theme === 'light')?'#000':'#fff',
              width: 120}}
          >исполнитель</b>
        </div>
        
        {context.log.map((item, index) => {
          if (role===''||role===accountsObj[item.userID].role){
            let backgroundHex = (accountsObj[item.userID].color)?parseInt(accountsObj[item.userID].color, 10).toString(16):'555';
            //console.log(accountsObj[item.userID].color)
            //console.log(backgroundHex)
            return(
              <Zoom key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: lay.window.width-27,
                    maxWidth: 600,
                    //height: 80,
                    //backgroundColor: (context.theme=='light')?"#ccc":"#333",
                    backgroundColor: '#'+backgroundHex,
                    color: (context.theme === 'light')?'#000':'#fff',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: 4,
                    margin: 4,
                    padding: 4,
                    //overflow: 'hidden',
                  }}>
                  <span
                    style={{
                      color: (context.theme === 'light')?'#000':'#fff',
                      padding: 4, 
                      width: 90, 
                      height: '100%',
                      marginRight: 4,
                      backgroundColor: (context.theme === 'light')?'#fff7':'#0009',
                    }}
                  >{moment(item.time*1000).format('lll')}</span>
                  <span
                    style={{
                      color: (context.theme === 'light')?'#000':'#fff', 
                      flex: 1, 
                      padding: 4, 
                      height: '100%',
                      marginRight: 4,
                      backgroundColor: (context.theme === 'light')?'#fff7':'#0009',
                      borderRadius: 0,
                    }}
                  >{item.action}</span>
                  <span
                    style={{color: (context.theme === 'light')?'#000':'#fff',
                      width: 120,
                      padding: 4, 
                      height: '100%',
                      backgroundColor: (context.theme === 'light')?'#fff7':'#0009',
                    }}
                  >{accountsObj[item.userID].name}</span>
                </div></Zoom>
            );
          }else{
            return '';
          }
        })}
          
      </Container>
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 300,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  ${'' /* background-color: #E6E6E6; */}
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;