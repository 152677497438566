import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import {lay} from '../../../../constants/Layout';

import {Context} from '../../../../functions/context';
import PrintListTimeItem from './PrintListTimeItem';
import ContraindicationsPrint from '../../../../components/ContraindicationsPrint';

export default function PrintList(props) {
  const context = useContext(Context);
  
  const onClickClose = () => {
    if (typeof props.onShowPrintList === 'function'){
      props.onShowPrintList(false);
    }
  };


  return(
    <PopUp
      style={{
        backgroundColor: (context.theme==='light')?'#fff':'#000',
        marginBottom: 80,
      }}
    >      
      <NoPrint>
        <Button 
          style={{margin: 4, }}
          color="secondary"
          variant="contained"
          onClick={onClickClose}>
              Закрыть
        </Button>
      </NoPrint> 
      <ContraindicationsPrint/>
      {props.mealTime!=='all' ? 
        <PrintListTimeItem
          currentMealTime={props.mealTime}
        />
        :
        context.cookOutPrint && Object.keys(context.cookOutPrint).map((keyTime, indexTime)=>
          <PrintListTimeItem
            key={keyTime+indexTime}
            currentMealTime={keyTime}
          />
        )
      }
      
    </PopUp>
  );
}

const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;  
  @page {
    margin: 0.1in;
  }
  @media print {
    /* здесь будут стили для печати 
    padding-top: 60px;*/
  }
`;

const NoPrint = styled.div`
  @media print {
    /* здесь будут стили для печати 
    display: none*/
  }
`;