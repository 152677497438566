import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';

import {Context} from '../functions/context';

export default function RoomForm(props) {
  const classes = useStyles();
  const [number, setNumber] = useState(props.value);
  const [edited, setEdited] = useState(false);
  const context = useContext(Context);
  const onSave = () =>{
  //в метод пробразсываем параметр props.type
    context.onSave('room.php', props.type, {id: props.id ,number: number});
    setEdited(false);
  };
  const onDelete = () => {

  };
  return (
    
    <Container
      style={{backgroundColor: (context.theme==='light') ? '#dde9':'#4459',
        borderColor: edited? '#d1d':'#778'}}
    >
      <div className={classes.root}>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >{props.title}</p>
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setNumber(e.target.value);
            
            setEdited(true);
          }}
          value = {number}
          type="number"
          id="outlined-helperText"
          label={props.label}
          helperText={props.helperText}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'палата'}</InputAdornment>,
          }}
        />
          
        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>

        {props.type==='new'&&edited&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                onSave();
                setEdited(false);
                setNumber('');
              }}>
              добавить
            </Button>
        }
        {props.type==='edit'&&edited&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                onSave();
                setEdited(false);
              }}>
              изменить
            </Button>
        }
        {/* {props.type==='edit'&&
            <Button 
              color="secondary"
              variant="contained"
              onClick={()=>onDelete()}>
              удалить
            </Button>
          } */}

      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 140,
    transition: '1s'
  },
  button: {
    transition: '1s'
  }
  
}));
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  ${'' /* 
height: 280px;
 */}  
  margin: 5px;
  overflow: hidden;
  transition: '1s';
  border: 2px solid #778;
  border-radius: 8px;
`;