import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ColorPicker } from 'material-ui-color';

import {Context} from '../functions/context';

import Zoom from 'react-reveal/Zoom';

export default function AccountForm(props) {
  const classes = useStyles();
  const [name, setName] = useState(props.item.name);
  const [color, setColor] = useState(+props.item.color);
  //const [colorValue, setColorValue] = useState(props.item.color.toString(16))
  const [role, setRole] = useState(props.item.role);
  const [password, setPassword] = useState(props.item.password);
  const [settingHeight, setSettingHeight] = useState(true);

  const [edited, setEdited] = useState(false);
  const context = useContext(Context);
  const onSave = () =>{
  //в метод пробразсываем параметр props.type
    context.onSave('accountForm.php', props.type, {
      id: props.id, 
      role: role, 
      password: password, 
      name: name,
      color: color,
    });
    setEdited(false);
  };
  const onDelete = () => {
  };
  return (
    
    <Container
      style={{
        backgroundColor: (context.theme==='light') ? '#dde9':'#4459',
        borderColor: edited? '#d1d':'#778',
        height: settingHeight? 100:620,
        justifyContent: 'flex-start',
        transition: '1s',
      }}
    >
      <div className={classes.root}>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
          onClick={()=>setSettingHeight(!settingHeight)}
        >{props.title}</p>
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setName(e.target.value);
            setEdited(true);
          }}
          value = {name}
          type="text"
          id="outlined-helperText"
          label={props.label}
          helperText={props.helperText}
          variant="outlined"
          placeholder='имя'
        />
        <ColorPicker 
          disableAlpha = {true}
          onChange={(e)=>{
            //console.log(e)
            //setColor(e.hex)
            //setColorValue(e.value)
            setColor(e.value);
            setEdited(true);
          }}
          format = "hex"
          value = {color}
          hideTextfield = {true}
        />
        <ButtonGroup 
          className={classes.margin16} 
          size="large" color="primary" 
          orientation="vertical"
          aria-label="vertical large primary button group">
          {context.roles.map((item, index)=>{
            return(
              <Button 
                key={index}
                className={classes.button}
                variant={(role===item)?'contained':'outlined'}
                onClick={()=>{
                  setRole(item);
                  setEdited(true);
                }}
              >{item}</Button>
            );
          })}
        </ButtonGroup>


        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >пароль</p>
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setPassword(e.target.value);
            setEdited(true);
          }}
          value = {password}
          type="text"
          id="outlined-helperText"
          label={props.label}
          helperText={props.helperText}
          variant="outlined"
          placeholder='пароль'
        />
        <div style={{height: (props.type==='new'&&edited)?56:0, transition: '1s'}}>
          {props.type==='new'&&edited&&
              <Zoom opposite when={props.type==='new'&&edited}>
                <Button 
                  color="primary"
                  variant="contained"
                  onClick={()=>{
                    onSave();
                    setEdited(false);
                    setName('');
                    setRole('');
                    setPassword('');
                  }}>
                  добавить
                </Button>
              </Zoom>
          }
        </div>
        <div style={{height: (props.type==='edit'&&edited)?56:0, transition: '1s'}}>
          {props.type==='edit'&&edited&&
            <Zoom opposite when={props.type==='edit'&&edited}>
              <Button 
                color="primary"
                variant="contained"
                onClick={()=>{
                  onSave();
                  setEdited(false);
                }}>
                изменить
              </Button>
            </Zoom>
          }
        </div>
        {/* {props.type==='edit'&&
            <Button 
              color="secondary"
              variant="contained"
              onClick={()=>onDelete()}>
              удалить
            </Button>
          } */}

      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    height: 620,
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 152,
    transition: '1s'
  },
  button: {
    transition: '1s'
  }
  
}));
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 160px;
  ${'' /* 
height: 280px;
 */}  
  margin: 5px;
  overflow: hidden;
  transition: '1s';
  border: 2px solid #778;
  border-radius: 8px;
`;