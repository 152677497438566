import React, { useContext, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import styled, { keyframes } from 'styled-components';

import {lay} from '../../../constants/Layout';
import {Context} from '../../../functions/context';

export default function Tasks(props) {

  const context = useContext(Context);

  return(
    <Block>
      <NoPrint>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: lay.window.width-27,
            maxWidth: 500,
            height: 40,
            backgroundColor: (context.theme==='light')?'#ccc':'#333',
            color: (context.theme==='light')?'#000':'#fff',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: 4,
            margin: 4,
            padding: 4,
          }}>
          <b
            style={{color: (context.theme==='light')?'#000':'#fff', flex: 1, marginRight: 4}}
          >Блюдо</b>
          {/* 
        <b
          style={{color: (context.theme==='light')?'#000':'#fff',width: 90, marginRight: 4}}
        >Диета</b>
         */}
          <b
            style={{color: (context.theme==='light')?'#000':'#fff',
              width: 70}}
          >Кол- во</b>
        </div>
      </NoPrint>
      {context.mealTimes.map((itemTime, indTime) => {
        let keyTime = itemTime.name;
        if(keyTime===props.mealtime || props.mealtime === 'all'){
          let additionalMeals = [];
          context.additionalMealsComments.map((commentObjItem, commentObjIndex)=>{
            if (commentObjItem.timeID === itemTime.id){
              additionalMeals = [...additionalMeals, commentObjItem];
            }
          });
          return(
            <PageNoBreakInside
              key = {keyTime}>
              <h3
                style={{
                  color: (context.theme==='light') ? '#224':'#bbf',
                }}>
                {keyTime}
              </h3>
              <PageNoBreakInside>
                {additionalMeals.map((commentObjItem, commentObjIndex)=>
                  <Row
                    key={commentObjItem.id}
                    style={{
                      backgroundColor: (context.theme==='light')?'#6cc':'#044',
                      color: (context.theme==='light')?'#000':'#fff',
                              
                    }}>
                    <span
                      style={{
                        color: (context.theme==='light')?'#000':'#fff', 
                        flex: 1, 
                        marginRight: 4}}
                    >{commentObjItem.comment}</span>
                              
                    <span
                      style={{
                        color: (context.theme==='light')?'#000':'#fff',
                        width: 40}}
                    >1</span>
                  </Row>
                )}
              </PageNoBreakInside>
              {Object.keys(context.cookList[keyTime]).map((keyType, indType)=>{
                return(
                  <>
                    <NoPrint>
                      <p
                        key={keyType+keyTime}
                        style={{
                          color: (context.theme==='light') ? '#224':'#bbf',
                        }}>
                        {keyType}
                      </p>
                    </NoPrint>
                    {Object.keys(context.cookList[keyTime][keyType]).map((keyMealId, indMealId)=>{
                      if (context.mealsNumb[keyMealId]!==undefined){ 
                        return(
                          <PageNoBreakInside
                            key = {indMealId}>
                            <Row
                              style={{
                                backgroundColor: (context.theme==='light')?'#ccc':'#333',
                                color: (context.theme==='light')?'#000':'#fff',
                              
                              }}>
                              <span
                                style={{
                                  color: (context.theme==='light')?'#000':'#fff', 
                                  flex: 1, 
                                  marginRight: 4}}
                              >{context.mealsNumb[keyMealId].name}</span>
                              {/* 
                            <span
                              style={{color: (context.theme==='light')?'#000':'#fff',width: 90, marginRight: 4}}
                            >{context.mealsNumb[keyMealId].diet.name}</span>
                             */}
                              <span
                                style={{
                                  color: (context.theme==='light')?'#000':'#fff',
                                  width: 40}}
                              >{context.cookList[keyTime][keyType][keyMealId]}</span>
                            </Row>
                          </PageNoBreakInside>
                        );
                      }
                      return(null);
                    })}
                  </>
                );
              })}
            </PageNoBreakInside>
          );
        }else{
          return null;
        }
              
      })
      }
    </Block>
  );
 
}

const Row = styled.div`
  display: flex;
    flex-direction: row;
    width: ${lay.window.width-27}px;
    max-width: 500px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
  margin: 4px;
  padding: 4px;
  @media print {
    padding: 2px;
    margin: 0px;
    border-width: 1px;
    border-style: groove;
    border-color: #222;
    background-color: #0000 !important;
    border-radius: 0px;
    page-break-inside: avoid;
  }
`;
const NoPrint = styled.div`
  @media print {
    /* здесь будут стили для печати */
    display: none
  }
`;

const PageNoBreakInside = styled.div`
  display: block;
  position: relative;
  page-break-inside: avoid;
  
`;
const Block = styled.div`
  display: block;
  position: relative;  
   
`;