import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import {lay} from '../../../../constants/Layout';

import {Context} from '../../../../functions/context';
import PrintTableTimeItem from './PrintTableTimeItem';
import ContraindicationsPrint from '../../../../components/ContraindicationsPrint';

export default function PrintTable(props) {
  const context = useContext(Context);
  
  const onClickClose = () => {
    if (typeof props.onShowPrintTable === 'function'){
      props.onShowPrintTable(false);
    }
  };


  return(
    <PopUp
      style={{
        backgroundColor: (context.theme==='light')?'#fff':'#000', 
        marginBottom: 80,
      }}
    >
      <NoPrint>
        <Button 
          style={{margin: 4,}}
          color="secondary"
          variant="contained"
          onClick={onClickClose}>
          Закрыть
        </Button> 
      </NoPrint>
      {/* <ContraindicationsPrint/> */}
      <RowWrap>
        {//тут придётся снова заново собирать данные в глубокий ассоциативный массив
          context.cookRoomsPrint && Object.keys(context.cookRoomsPrint).sort().map((keyRoom, indexRoom)=>
            <PrintTableTimeItem
              key={keyRoom}
              currentMealTime={props.mealTime}
              room={keyRoom}
            />
          )
        }
      </RowWrap>  
    </PopUp>
  );
}

const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  ${'' /* position: fixed;
  z-index: 200;
  top: 0px;
  left: 0px; 
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  */}
  
  width: 100vw;
  @page {
    margin: 0.1in;
  }
  @media print {
    /* здесь будут стили для печати */
    padding-top: 60px;
  }
`;

const RowWrap = styled.div`
${'' /* 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
   */}
   
  display: block;
  width: 756px;
`;

const NoPrint = styled.div`
  @media print {
    /* здесь будут стили для печати */
    display: none;
  }
`;