import React, { Component } from 'react';
import { Dots } from 'react-activity';
import {Context} from '../functions/context';

class WaitScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#345e',
          flexDirection: 'column',
          zIndex: 250,
        }}
      >
        <Dots color="#fff" size={100} speed={1} animating={true} />
        <h4 style={{color: '#fff', padding: 30}}>{'Вы вошли как '+this.context.userRole}</h4>
        <h4 style={{color: '#fff', padding: 30}}>дождитесь загрузки данных</h4>
      </div>
    );
  }
}

WaitScreen.contextType = Context;
export default WaitScreen;