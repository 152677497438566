import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Brightness6Icon from '@material-ui/icons/Brightness6';
import Fab from '@material-ui/core/Fab';
import {Context} from '../functions/context';


import moment from 'moment';
import 'moment/locale/ru';
const today = moment(+new Date()).format('ll');

function Top(props) {
  const context = useContext(Context);

  const week = [
    moment(+new Date()).format('ll'),
    moment((+new Date())+1000*60*60*24).format('ll'),
    moment((+new Date())+1000*60*60*24*2).format('ll'),
    moment((+new Date())+1000*60*60*24*3).format('ll'),
    moment((+new Date())+1000*60*60*24*4).format('ll'),
    moment((+new Date())+1000*60*60*24*5).format('ll'),
    moment((+new Date())+1000*60*60*24*6).format('ll'),
  ];
  const daysOfWeek = [
    +moment(+new Date()).format('E'),
    +moment((+new Date())+1000*60*60*24).format('E'),
    +moment((+new Date())+1000*60*60*24*2).format('E'),
    +moment((+new Date())+1000*60*60*24*3).format('E'),
    +moment((+new Date())+1000*60*60*24*4).format('E'),
    +moment((+new Date())+1000*60*60*24*5).format('E'),
    +moment((+new Date())+1000*60*60*24*6).format('E'),
  ];
  const classes = useStyles();
  const [updateDay, setUpdateDay] = useState(context.updateDay);

  const onSetDate = (e)=>{
    setUpdateDay(e);
    context.setUpdateDay(e);
    context.axiUpdate('Main', context.token, e, daysOfWeek[week.indexOf(e)]);
  };

  const onTargetDate = (e) => {
    onSetDate(e.target.value);
  };

  useEffect(()=>{
    context.userRole === 'буфетчица' && onSetDate(moment((+new Date())+1000*60*60*24).format('ll'));
  },[]);

  return (
    <div
      className={'top '+ classes.backgroundColor}
      style={{ 
        position: 'fixed',
        zIndex: 5,
      }}
    >
      <Fab 
        color="primary" 
        aria-label="add"
        onClick={()=>context.toggleTheme()}
      >
        <Brightness6Icon />
      </Fab>
      {(context.userRole==='буфетчица' || context.userRole==='повар') &&
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">день</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={updateDay}
            onChange={onTargetDate}
            label="день"
          >
            {week.map((item, index)=>
              <MenuItem key={index} value={item}>{item}</MenuItem>
            )
            }
          </Select>
        </FormControl>
      }
        
      {/*  <div style={{width: 80}}/> */}
    </div>
      
  );
  
}

export default Top;

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    width:200,
    marginLeft: 8,
    marginRight: 60
  },  
  color: {
    color: theme.palette.text.primary+'a',
    transition: '1s'
  },
  backgroundColor: {
    backgroundColor: theme.palette.backgroundColorTransparent,
    transition: '1s'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));