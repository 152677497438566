import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import {lay} from '../../../../constants/Layout';

import {Context} from '../../../../functions/context';
import BlockWrap from '../../../../styledComponents/BlockWrap';
import RoomsList from './RoomsList';

const pageWidth = 600;
export default function PrintListTimeItem(props) {
  const context = useContext(Context); 

  let localStyles = {
    itemDiv : {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      width: lay.window.width-27,
      maxWidth: pageWidth,
      minHeight: 30,
      backgroundColor: (context.theme==='light')?'#ddd':'#333',
      color: (context.theme==='light')?'#000':'#fff',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 4,
      margin: 4,
      padding: 4,
    },
    mealSpan: {
      color: (context.theme==='light')?'#000':'#fff', 
      flex: 1, 
      marginRight: 16,
      minWidth: 250,
    },
    roomNumbSpan: {
      color: (context.theme==='light')?'#000':'#fff',     
      marginRight: 16,
      minWidth: 20,
    }
  };

  let additionalMeals = [];
  let keyTimeId = 0;
  context.mealTimes.map((keyTimeItem, keyTimeIndex)=>{
    if (keyTimeItem.name === props.currentMealTime){
      keyTimeId = keyTimeItem.id;
    }
  });
  context.additionalMealsComments.map((commentObjItem, commentObjIndex)=>{
    if (commentObjItem.timeID === keyTimeId){
      additionalMeals = [...additionalMeals, commentObjItem];
    }
  });

  return(
    <BlockWrap pageWidth={pageWidth}>  
      {context.cookOutPrint 
      && context.cookOutPrint[props.currentMealTime] 
      && context.cookOutPrint[props.currentMealTime]['plateGroup']
      && Object.keys(context.cookOutPrint[props.currentMealTime]['plateGroup']).map((keyGroupType, indGroupType)=>{
        return(
          <BlockWrap
            key = {'pg' + indGroupType}
            pageWidth = {pageWidth}
          >
            <Page>
              <h3
                style={{color: (context.theme==='light')?'#000':'#fff',}}
              >{props.currentMealTime + ' :: ' + keyGroupType}</h3>
              <BlockWrap pageWidth={pageWidth}>
                {Object.keys(context.cookOutPrint[props.currentMealTime]['plateGroup'][keyGroupType]).map((keyGroupMeal, indGroupMealId)=>{        
                  return(
                    <BlockWrap
                      pageWidth={pageWidth}
                      key={indGroupMealId}
                    >
                      <div
                        style={localStyles.itemDiv}>
                        <span
                          style={{...localStyles.mealSpan, color: '#070'}}
                        >{keyGroupMeal}</span>
                        <span
                          style={localStyles.roomNumbSpan}
                        >{context.cookOutPrint[props.currentMealTime]['plateGroup'][keyGroupType][keyGroupMeal].length} шт.</span>
                        <RoomsList
                          rooms={context.cookOutPrint[props.currentMealTime]['plateGroup'][keyGroupType][keyGroupMeal]}
                        />                  
                      </div>
                    </BlockWrap>
                  );
                      
                })}
              </BlockWrap>
            </Page>
          </BlockWrap>
        );
      })
      } 
      {context.cookOutPrint 
      && context.cookOutPrint[props.currentMealTime] 
      && Object.keys(context.cookOutPrint[props.currentMealTime]).map((keyType, indType) => {
        if(keyType === 'plateGroup'){
          return '';
        }else{
          return(
            <BlockWrap
              key = {indType}
              pageWidth = {pageWidth}
            >
              <Page>
                <h3
                  style={{color: (context.theme==='light')?'#000':'#fff',}}
                >{props.currentMealTime + ' :: ' + keyType}</h3>
                <BlockWrap pageWidth={pageWidth}>
                  {Object.keys(context.cookOutPrint[props.currentMealTime][keyType]).map((keyMealId, indMealId)=>{
                    if (context.mealsNumb[keyMealId]!==undefined){ 
                      return(
                        <BlockWrap
                          pageWidth={pageWidth}
                          key={indMealId}
                        >
                          <div
                            style={localStyles.itemDiv}>
                            <span
                              style={localStyles.mealSpan}
                            >{context.mealsNumb[keyMealId].name}</span>
                            <span
                              style={localStyles.roomNumbSpan}
                            >{context.cookOutPrint[props.currentMealTime][keyType][keyMealId].length} шт.</span>
                            <RoomsList
                              rooms={context.cookOutPrint[props.currentMealTime][keyType][keyMealId]}
                            />
                          
                          </div>
                        </BlockWrap>
                      );
                    }else{
                      return(
                        <p
                          key={indMealId}
                          style={{
                            color: (context.theme==='light') ? '#822':'#f99' ,     
                            padding: 2
                          }}>
                          {`ошибка поиcка блюда № ${keyMealId}`}
                        </p>
                      );
                    }
                  })}
                </BlockWrap>
              </Page>
            </BlockWrap>
          ); 
        }
        
      })}
      
      {additionalMeals.map((itemMeal, indexMeal)=> {  
        return (
          <BlockWrap
            pageWidth={pageWidth}
            key={'comment'+indexMeal}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                width: lay.window.width-27,
                maxWidth: pageWidth,
                minHeight: 30,
                backgroundColor: (context.theme==='light')?'#2dd':'#144',
                color: (context.theme==='light')?'#000':'#fff',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 4,
                margin: 4,
                padding: 4,
              }}>
              <span
                style={{
                  color: (context.theme==='light')?'#000':'#fff', 
                  flex: 1, 
                  marginRight: 16,
                  minWidth: 250,
                }}
              >{itemMeal.comment}</span>
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: (context.theme==='light')?'#000':'#fff',
                    width: 50}}
                >{itemMeal.room}</span>
              </div>
            </div>
          </BlockWrap>
        );
      })}

    </BlockWrap>
  );
   
}
const Page = styled.div`
  display: block;
  position: relative;
  width: ${pageWidth+16}px;
  page-break-inside: avoid !important;
  @media print {
    page-break-inside: avoid !important;
  }
`;