import React, { Component, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {Context} from '../../../../functions/context';
import EntityButton from './EntityButton';

export default function EntityButtonGroup(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(Context);
 
  return (
    <ButtonGroup 
      className={classes.margin16} 
      size="large" color="primary" 
      orientation="vertical"
      aria-label="vertical large primary button group">
      {props.groupArray.map((variantItem, variantIndex)=>
        <EntityButton key={props.selectorType+variantIndex} variantItem={variantItem} {...props}/>
      )}
    </ButtonGroup>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s'
  },
  button: {
    transition: '1s'
  }
  
}));