import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';

export default function EntityButton(props) {
  const [indexEntity, setIndexEntity] = useState(-1);
  const [isCheck, toggleIsCheck] = useState(false);

  useEffect(()=>{
    if (typeof props.entity === 'object'){
      console.log(props.entity);
      let updatedIndex = props.entity.indexOf(+props.variantItem?.id);
      setIndexEntity(updatedIndex);
      toggleIsCheck(updatedIndex>-1);
    }
  },[props.entity]);
 
  const handleCheck = ()=>{
    let newEntity = [...props.entity];
    if (indexEntity>-1){
      newEntity.splice(indexEntity, 1);
    }else{
      newEntity.push(+props.variantItem.id);
    }
    if (typeof props.onSetEntity ==='function'){
      props.onSetEntity(newEntity);
      toggleIsCheck(!isCheck);
      if (typeof props.onSetEdited ==='function'){
        props.onSetEdited(true);
      }
    }
    
  };

  return(
    <Button 
      {...props}
      variant={isCheck?'contained':'outlined'}
      onClick={handleCheck}
    >{props.variantItem.name}</Button>
  );   
}
