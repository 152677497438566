import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import {lay} from '../../../../constants/Layout';

import {Context} from '../../../../functions/context';
import Tasks from '../Tasks';
import ContraindicationsPrint from '../../../../components/ContraindicationsPrint';

export default function PrintTask(props) {
  const context = useContext(Context);
  
  const onClickClose = () => {
    if (typeof props.onShowPrint === 'function'){
      props.onShowPrint(false);
    }
  };


  return(
    <PopUp
      style={{
        backgroundColor: (context.theme==='light')?'#fff':'#000',
        marginBottom: 80,
      }}
    >      
      <NoPrint>
        <Button 
          style={{margin: 4, }}
          color="secondary"
          variant="contained"
          onClick={onClickClose}>
              Закрыть
        </Button>
      </NoPrint>
      <ContraindicationsPrint/>
      <NoPrint>
        <h3
          style={{color: (context.theme==='light')?'#000':'#fff',}}
        >{props.mealTime}</h3>
      </NoPrint> 
      <Tasks
        mealtime = {props.mealTime}
      />
  
    </PopUp>
  );
}

const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  @media print {
    /* здесь будут стили для печати */
    padding-top: 140px;
  }
`;

const NoPrint = styled.div`
  @media print {
    /* здесь будут стили для печати */
    display: none
  }
`;