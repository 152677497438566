import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Top from '../../../components/Top';
import TopAnswer from '../../../components/TopAnswer';
import BottomShare from '../../../components/BottomShare';
import InputForm from '../../../components/InputForm';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import moment from 'moment';
import 'moment/locale/ru';
import { CSVLink } from 'react-csv';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import Logo from '../../../assets/logo';
import {lay} from '../../../constants/Layout';
import { Height } from '@material-ui/icons';



import {Context} from '../../../functions/context';
import PrintList from './PrintList';
import PrintTable from './PrintTable';
import Tasks from './Tasks';
import PrintTask from './PrintTask';



export default function MainСook(props) {

  const context = useContext(Context);
  const classes = useStyles();
  const [mealtime, setMealtime] = useState('all');
  const [isShowPrintList, setShowPrintList] = useState(false);
  const showPrintList = () => {
    setShowPrintList(true);
  };

  const [isShowPrintTable, setShowPrintTable] = useState(false);
  const showPrintTable = () => {
    setShowPrintTable(true);
  };

  const [isShowPrintTasks, setShowPrintTasks] = useState(false);
  const showPrintTasks = () => {
    setShowPrintTasks(true);
  };

  const [flatTable, setFlatTable] = useState([]);

  useEffect(()=>{
    let newTable = [
      [moment().format('LLL')],
      ['наименование', 'количество']
    ];
    context.mealTimes.map((itemTime, indTime) => {
      let keyTime = itemTime.name;
      if(keyTime===mealtime || mealtime === 'all'){
        newTable=[...newTable, [keyTime]];
        Object.keys(context.cookList[keyTime]).map((keyType, indType)=>{
          newTable=[...newTable, [keyType]];
          Object.keys(context.cookList[keyTime][keyType]).map((keyMealId, indMealId)=>{
            newTable=[...newTable, [context.mealsNumb[keyMealId].name, context.cookList[keyTime][keyType][keyMealId]]];
          });
        });
      }
    });
    console.log(newTable);
    setFlatTable(newTable);
  },[context.cookList]);


  const today = moment(+new Date()).format('ll');
  return (
    
    <>
      {!isShowPrintTable && !isShowPrintList && !isShowPrintTasks && <Top/>}
      {isShowPrintList?
        <PrintList 
          mealTime={mealtime}
          onShowPrintList={setShowPrintList}
        />
        :null
      }
      {isShowPrintTable&&
        <PrintTable
          mealTime={mealtime}
          onShowPrintTable={setShowPrintTable}
        />
      }
      {isShowPrintTasks&&
        <PrintTask
          mealTime={mealtime}
          onShowPrint={setShowPrintTasks}
        />
      }
      {!isShowPrintTable && !isShowPrintList && !isShowPrintTasks &&
      <Container
      //className={classes.root}
        style={{
          flexWrap: 'nowrap',
          flexDirection: 'column',
          alignItems: 'centre',
          justifyContent:'flex-start',
          paddingTop:  80,
          height: lay.window.height-80,
          transition: '1s',
          
          backgroundColor: (context.theme==='light')?'#fff':'#000',
        }}
      >
        {context.mealTimes &&
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">приём пищи</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={mealtime}
              onChange={(e)=>{
                setMealtime(e.target.value);
              }}
              label="приём пищи"
            >
              <MenuItem key={0} value="all"><em>Все</em></MenuItem>
              {context.mealTimes.map((item, index)=>
                <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
              )
              }
            </Select>
          </FormControl>
        }
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}>
          {mealtime &&
            <Button 
              style={{margin: 4, marginBottom: 20}}
              color="secondary"
              variant="contained"
              onClick={showPrintList}>
                печать выкладки
            </Button> 
          }
          <Button 
            style={{margin: 4, marginBottom: 20}}
            color="primary"
            variant="contained"
            onClick={showPrintTasks}>
                печать задания
          </Button> 
          {mealtime &&
            <Button 
              style={{margin: 4, marginBottom: 20}}
              color="secondary"
              variant="contained"
              onClick={showPrintTable}>
                печать палат
            </Button> 
          }
        </div>
        {flatTable ?
          <CSVLink  
            data={flatTable} 
            //headers={CSVheaders} 
            filename={`задание повору ${mealtime} ${moment().format('LLL')}.csv`}
            separator=";">
            Скачать задание Excel
          </CSVLink>
          : null
        }
        <Tasks
          mealtime = {mealtime}
        />

      </Container>}
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 300,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  ${'' /* background-color: #E6E6E6; */}
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;