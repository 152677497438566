import React, { Component, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axi from '../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';

import {Context} from '../functions/context';

export default function UniTextForm(props) {
  const classes = useStyles();
  //const [name, setName] = useState(props.item.name);
  //const [orderNumber, setOrderNumber] = useState(props.item.orderNumber);
  const [item, setItem] = useState(props.item);
  const [edited, setEdited] = useState(false);
  const context = useContext(Context);

  const onChange = (e, fieldKey) => {
    //setOrderNumber(e.target.value);
    let newItem = {...item};
    newItem[fieldKey] = e.target.value;
    setItem(newItem);
    setEdited(true);
  };


  const onSave = () =>{
    //в метод пробразсываем параметр props.type
    //context.onSave(props.api, props.type, {id: props.item.id, name: name, orderNumber: orderNumber});
    context.onSave(props.api, props.type, item);
    setEdited(false);
  };
  const onDelete = () => {
    //context.onSave(props.api, 'delete', {id: props.item.id, name: name, orderNumber: orderNumber});
    context.onSave(props.api, props.type, item);
    setEdited(false);
  };

  
  useEffect(()=>{
    setItem(props.item);
  },[props.item]);

  return (
    <Container
      style={{backgroundColor: (context.theme==='light') ? '#dde9':'#4459',
        borderColor: edited? '#d1d':'#778'}}
    >
      <div className={classes.root}>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >{props.title}</p>
        <TextField
          className={classes.margin16}
          onChange={(e)=> {
            let fieldKey = props.key1field ? props.key1field : 'name';
            onChange(e, fieldKey);
          }}
          value = {props.key1field ? item[props.key1field] : item.name}
          type={props.type1field ? props.type1field : 'text'}
          id="outlined-helperText"
          label={props.label1field ? props.label1field : 'название'}
          helperText={props.helperText1}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
          }}
        />
        <TextField
          className={classes.margin16}
          onChange={(e)=> {
            let fieldKey = props.key2field ? props.key2field : 'orderNumber';
            onChange(e, fieldKey);
          }}
          value = {props.key2field ? item[props.key2field] : item.orderNumber}
          type={props.type2field ? props.type2field : 'number'}
          id="outlined-helperText"
          label = {props.label2field ? props.label2field : 'порядковый номер'}
          helperText={props.helperText2}
          variant="outlined"
        />

        
        {props.extraFields ?
          Object.keys(props.extraFields).map((extraField, extraFieldIndex) =>{
            return (
              props.extraFields[extraField].label ?
                (
                  props.extraFields[extraField].type === 'select' ?
                    <FormControl 
                      style={{flex: 1 , minWidth: '200px', margin: 8}}
                      helperText={props.extraFields[extraField].helperText}
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-label">{props.extraFields[extraField].label}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={item[extraField]}
                        label={props.extraFields[extraField].label}
                        onChange={(e)=> {
                          onChange(e, extraField);
                        }}
                      >
                        {/* мы используем то же имя ключа что и список в контексте */}
                        {context[extraField] && context[extraField].map((menuItem, menuIndex)=>
                          <MenuItem 
                            key={menuIndex} 
                            value={menuItem.id}>
                            {menuItem.name}
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                    :
                    <TextField
                      className={classes.margin16}
                      onChange={(e)=> {
                        onChange(e, extraField);
                      }}
                      value = {item[extraField]}
                      type={props.extraFields[extraField].type ? props.extraFields[extraField].type : 'text'}
                      id="outlined-helperText"
                      label = {props.extraFields[extraField].label}
                      helperText={props.extraFields[extraField].helperText}
                      variant="outlined"
                    />
                )
                :''
            );
          }
          )
          : ''
        }

        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>

        {props.type==='new'&&edited&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                setEdited(false);
                onSave();
              }}>
              добавить
            </Button>
        }
        {props.type==='edit'&&edited&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                setEdited(false);
                onSave();
              }}>
              изменить
            </Button>
        }
        {props.type==='edit'&&
            <Button 
              color="secondary"
              variant="contained"
              onClick={()=>onDelete()}>
              удалить
            </Button>
        }

      </div>
    </Container>);
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s'
  },
  button: {
    transition: '1s'
  }
  
}));
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 5px;
  overflow: hidden;
  transition: '1s';
  border: 2px solid #778;
  border-radius: 8px;
`;