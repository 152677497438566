/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect, Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/ru';

import {lay} from '../../../../constants/Layout';
import { Height } from '@material-ui/icons';
import {Context} from '../../../../functions/context';
import { TextField } from '@material-ui/core';
import Paragraph from '../../../../styledComponents/Paragraph';

export default function MealTimeItem(props) {

  const classes = useStyles();
  const context = useContext(Context);
  let {
    itemTime, 
    indexTime,
    orders,
  } = props;

  const today = moment(+new Date()).format('ll');
  const [additionalMeal, setAdditionalMeal] = useState(props.additionalMeal);
  

  const handleOnSetMealsTimesComment = (e)=>{
    setAdditionalMeal(e.target.value);
    if (typeof props.onSetAdditionalMeal === 'function'){
      props.onSetAdditionalMeal(itemTime.id, e.target.value);
    }
  };


  const handleOnPressMenuItem = (itemMeal, itemTimeId, itemTypeId, selectedRoomDietId, checked) => {
    if(typeof props.handleOnPressMenuItem === 'function'){
      props.handleOnPressMenuItem(itemMeal, itemTimeId, itemTypeId, selectedRoomDietId, checked);
    }
  };

  let readOnly = false;
  if(itemTime.timeToCook&&context.updateDay===today){
    let H = itemTime.timeToCook[0]+itemTime.timeToCook[1];
    let M = itemTime.timeToCook[3]+itemTime.timeToCook[4];
    let minutes = +H*60 + +M;
    if (minutes<props.nowMinutes){
      readOnly=true;
    }
  }
  return(
    <Container
      style={{
        flexWrap: 'nowrap',
        width: lay.window.width,
        overflowY: 'hidden',
      }}
    >
      <h3
        style={{
          color: (context.theme==='light') ? '#224':'#bbf',
        }}>
        {itemTime.name+' начало готовки в '+ itemTime.timeToCook}
      </h3>
      <MicroP>Палата {props.selectedRoom.number}</MicroP>
      {context.mealsTypes.map((itemType, indexType)=>{
        let itemProteins = 0;
        let itemFats = 0;
        let itemCarbs = 0;
        let itemCaloric = 0;
        let comment = '';
        let mealsButtons = [];
        context.meals.map((itemMealFromAll, indexMealFromAll)=>{
          if ((itemMealFromAll.time.indexOf(+itemTime.id)>-1)  
                        &&(itemMealFromAll?.type === itemType.id) 
                        &&(itemMealFromAll?.diet?.indexOf(+props.selectedRoom.diet.id)>-1)
                        //&&(itemMealFromAll.weekday.indexOf(isoWeekDay)>-1)
          ){
            mealsButtons = [...mealsButtons, itemMealFromAll];
          }
        });
        return(
          <Fragment
            key={'type'+indexType}
          >
            {mealsButtons.length > 0 &&
                  <Container
                    style={{
                      flexWrap: 'nowrap',
                      alignItems: 'center',
                      width: lay.window.width,
                      overflowY: 'hidden',
                    }}
                  >
                    <span
                      style={{
                        color: (context.theme==='light') ? '#222':'#eef',
                      }}>
                      {itemType.name}
                    </span>
                    <ButtonGroup 
                      className={classes.margin16} 
                      disabled={readOnly}                          
                      size="medium" color="primary" 
                      orientation="vertical"
                      aria-label="vertical medium primary button group">

                      {mealsButtons.map((itemMeal, indexMeal)=>{
                        let checked = false;
                        orders.map((orderCheckItem, orderCheckIndex)=>{
                          if ((orderCheckItem.mealTime === itemTime.id)&&
                                (orderCheckItem.mealType === itemType.id)&&
                                (orderCheckItem.roomName === props.selectedRoom.number)){
                            comment = orderCheckItem.comment;
                            if(orderCheckItem.meal === itemMeal.id){
                              checked = true;
                              itemProteins = itemMeal.proteins;
                              itemFats = itemMeal.fats;
                              itemCarbs = itemMeal.carbs;
                              itemCaloric = itemMeal.caloric;
                            }
                          }
                        });
                        return(
                          <Button 
                            key = {'meal'+indexMeal}
                            disabled = {props.isWaiting || readOnly}
                            className = {classes.button}
                            variant = {checked?'contained':'outlined'}
                            onClick = {()=>{
                              handleOnPressMenuItem(itemMeal, itemTime.id, itemType.id, props.selectedRoom.diet.id, checked);
                            }}
                            
                          >{itemMeal.name}</Button>
                        );
                      })
                      }
                    </ButtonGroup>
                    <div>
                      {/*
                    <TextField
                      className={classes.margin16}
                      onChange={(e)=>{
                        handleOnSetComment(e.target.value, itemTime.id, itemType.id, props.selectedRoom.diet.id);
                      }}
                      value = {comment}
                      type="text"
                      id="outlined-helperText"
                      label={'комментарий'}
                      variant="outlined"
                    />
                     
                    <div
                      style={{
                        display: 'flex',
                        flexDirection:'column'
                      }}
                    >
                      <div style={localStyles.calcResult}>
                        <span
                          style={{
                            color: (context.theme==='light') ? '#222':'#eef',
                          }}>Белки:</span>
                        <span style={{
                          color: (context.theme==='light') ? '#222':'#eef',
                        }}>{itemProteins+' грамм'}</span>
                      </div>
                      <div style={localStyles.calcResult}>
                        <span
                          style={{
                            color: (context.theme==='light') ? '#222':'#eef',
                          }}>Жиры:</span>
                        <span style={{
                          color: (context.theme==='light') ? '#222':'#eef',
                        }}>{itemFats+' грамм'}</span>
                      </div>
                      <div style={localStyles.calcResult}>
                        <span
                          style={{
                            color: (context.theme==='light') ? '#222':'#eef',
                          }}>Углеводы:</span>
                        <span style={{
                          color: (context.theme==='light') ? '#222':'#eef',
                        }}>{itemCarbs+' грамм'}</span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection:'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: 32,
                          width: 200,
                        }}
                      >
                        <span
                          style={{
                            color: (context.theme==='light') ? '#222':'#eef',
                          }}>Калорийность:</span>
                        <span style={{
                          color: (context.theme==='light') ? '#222':'#eef',
                        }}>{itemCaloric+' кКал'}</span>
                      </div>
                    </div>
                     */}
                    </div>
                  </Container>
            }
          </Fragment>
        );
      })}
      <TextField
        className={classes.margin16}
        onChange={handleOnSetMealsTimesComment}
        value = {additionalMeal}
        type="text"
        id="outlined-helperText"
        label={'дополнительно на '+itemTime.name}
        variant="outlined"
      />
    </Container>);
      
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  }
  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;

const MicroP = styled.p`
  font-size: 10px;
  margin-bottom: 16px;
  margin-top: -16px;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 220;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const localStyles = {
  calcResult: {
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    width: 200,    
    borderBottomColor: '#404040',
    borderWidth: 1,
    borderBottomStyle: 'dotted',
  }
};