import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Top from '../../../components/Top';
import {lay} from '../../../constants/Layout';
import {Context} from '../../../functions/context';

import MealsForm from './MealsForm/MealsForm';
import emptyMealsForm from '../../../constants/emptyMealsForm';
import MealsList from './MealsList';


export default function Meals(props) {

  const context = useContext(Context);
  const [menuOpenStatus, setMenuOpenStatus] = useState(true);
  const menuOpenStatusToggle = () => {
    setMenuOpenStatus(!menuOpenStatus);
  };

  const [selectedMeals, setSelectedMeals] = useState([]);
  const [selectedMealsObjs, setSelectedMealsObjs] = useState([]);

  const route = (u) => {
    const uIndex = selectedMeals.indexOf(u);
    let newSelectedMeals = [...selectedMeals];
    let newSelectedMealsObjs = [...selectedMealsObjs];
    if (uIndex===-1){
      setSelectedMeals([...selectedMeals, u]);
      setSelectedMealsObjs([...selectedMealsObjs, context.meals[u]]);
    }else{
      if (uIndex>-1){
        newSelectedMeals.splice(uIndex, 1);
        setSelectedMeals(newSelectedMeals);
        newSelectedMealsObjs.splice(uIndex, 1);
        setSelectedMealsObjs(newSelectedMealsObjs);
      }
    }
  };

  const updateSelectedMeals = (uIndex) => {
    let newSelectedMeals = [...selectedMeals];
    let newSelectedMealsObjs = [...selectedMealsObjs];
    newSelectedMeals.splice(uIndex, 1);
    setSelectedMeals(newSelectedMeals);
    newSelectedMealsObjs.splice(uIndex, 1);
    setSelectedMealsObjs(newSelectedMealsObjs);
  };
  
  return (
    <>
      <Top />
      <Container
        //className={classes.root}
        style={{
          flexWrap: (lay.window.width>700) ? 'wrap':'nowrap',
          flexDirection: (lay.window.width>700) ? 'row':'column',
          alignItems: (lay.window.width>700) ? 'flex-start':'centre',
          justifyContent:'flex-start',
          paddingTop:  80,
          minHeight: lay.window.height-80,
          transition: '1s',
          
          backgroundColor: (context.theme==='light')?'#fff':'#000',
        }}
      >
        <MealsList
          route={route}
          menuOpenStatus={menuOpenStatus}
          list={context.meals}
          menuOpenStatusToggle={menuOpenStatusToggle}
          selectedMeals={selectedMeals}
        />  
        <MealsForm
          type='new'
          title='Добавить новое блюдо'
          item={{...emptyMealsForm}}
          onSave={(data)=>{
            context.axiMealsForm('new', data);
          }}
          onDelete={()=>{}}
          endAdornment=''
        />
        {selectedMealsObjs.map((item, index)=>
          <MealsForm
            key={item.id}
            type='edit'
            title={'редактировать блюдо '+item.name}
            item={item}
            selectedIndex={index}
            onSave={(data)=>{
              context.axiMealsForm('edit', data);
            }}
            onDelete={async (data)=>{
              let isDelete = await window.confirm('Вы действительно хотите удалить '+ data.name +'?');
              isDelete && context.axiMealsForm('delete', data);
              isDelete && updateSelectedMeals(index);
            }}
            endAdornment=''
          />
        )}
      </Container>
    </>
  );
}

/* 
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 300,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  }
  
}));
 */

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;