import React, { Fragment, useContext, useEffect, useState } from 'react';

import {Context} from '../../../../functions/context';


export default function RoomsList(props) {
  const context = useContext(Context);
  const [rooms, setRooms] = useState([]);

  useEffect(()=>{
    if(typeof props.rooms === 'object'){
      let newRooms = [...props.rooms];
      newRooms.sort();
      setRooms(newRooms);
    }
  },[props.rooms]);

  return(
    <div 
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
      }}
    >
      {
        rooms.map((itemRoom, indexRoom)=>
          <span
            key={indexRoom}
            style={{
              color: (context.theme==='light')?'#000':'#fff',
              width: 50}}
          >{itemRoom+', '}</span>
        )
      }
    </div>                        
  );
}