/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/ru';

import {lay} from '../../../../constants/Layout';
import { Height } from '@material-ui/icons';
import {Context} from '../../../../functions/context';
import { TextField } from '@material-ui/core';
import Paragraph from '../../../../styledComponents/Paragraph';
import MealTimeItem from './MealTimeItem';
import axi from '../../../../functions/axiosf';


export default function BarmaidModal(props) {

  const classes = useStyles();

  const context = useContext(Context);

  const [edited, setEdited] = useState(0);
  const [orders, setOrders] = useState(props.orders);
  const [additionalMealsComments, setAdditionalMealsComments] = useState(props.additionalMealsComments ? props.additionalMealsComments : []);

  const [proteins, setProts] = useState(0);
  const [fats, setFats] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [calorics, setCals] = useState(0);

  const [isWaiting, setIsWaiting] = useState(false);

  const nowTime = moment(+new Date()).format('kk:mm');
  let nowH = nowTime[0]+nowTime[1];
  const nowM = nowTime[3]+nowTime[4];
  if(nowH==='24'){
    nowH = '00';
  }
  const nowMinutes = +nowH*60 + +nowM;
  //console.log(nowTime+': '+nowH+', '+nowM+' в минутах '+nowMinutes);
  //let isoWeekDay = moment(context.updateDay).isoWeekday();

  const onSave=()=>{
    if (typeof props.onSave === 'function'){
      props.onSave(orders);
    }
    setEdited(0);
  };
  
  const handleOnPressMenuItem = (mealObj, time, type, diet, checked)=>{
    let newOrders=[];
    if (!checked){
      let newOrder = {
        meal: mealObj.id,
        mealTime: time,
        mealType: type,
        diet: diet,
        comment: '',
        roomName: props.selectedRoom.number,
      };
      let flagUpdated = false;
      orders.map((orderItem, orderIndex) => {
        if (orderItem.mealTime == time &&
            orderItem.mealType == type){
          newOrder.comment = orderItem.comment;
          newOrders = [...newOrders, newOrder];
          console.log(newOrder);
          flagUpdated = true;
        }else{
          newOrders = [...newOrders, orderItem];
        }
      });
      if (!flagUpdated){
        newOrders = [...newOrders, newOrder];
      }
      setOrders(newOrders);

      if (typeof props.onSetOrders === 'function'){
        props.onSetOrders(newOrders);
      }
    }
    calcCalorics();
    setEdited(1);
  };

  const handleOnSetComment = (comment, time, type, diet) => {
    let newOrders=[];
    let newOrder = {
      meal: 0,
      mealTime: time,
      mealType: type,
      diet: diet,
      comment: comment,/////дописать
    };
    let flagUpdated = false;
    orders.map((orderItem, orderIndex) => {
      if (orderItem.mealTime == time &&
            orderItem.mealType == type){
        newOrder.meal = orderItem.meal;
        newOrders = [...newOrders, newOrder];
        console.log(newOrder);
        flagUpdated = true;
      }else{
        newOrders = [...newOrders, orderItem];
      }
    });
    if (!flagUpdated){
      newOrders = [...newOrders, newOrder];
    }
    setOrders(newOrders);

    if (typeof props.onSetOrders === 'function'){
      props.onSetOrders(newOrders);
    }
    setEdited(1);
  };

  const calcCalorics=(selectedRoomProp=props.selectedRoom)=>{
    let newProteins = 0;
    let newFats = 0;
    let newCarbs = 0;
    let newCaloric = 0;

    
    orders.map((orderItem, orderIndex) => {
      context.meals.map((mealItem, mealIndex)=>{
        if (orderItem.meal === mealItem.id){
          newProteins += (+mealItem.proteins);
          newFats += (+mealItem.fats);
          newCarbs += (+mealItem.carbs);
          newCaloric += (+mealItem.caloric);
        }
      });
    });
    
    setProts(newProteins);
    setFats(newFats);
    setCarbs(newCarbs);
    setCals(newCaloric);
  };

  const handleOnSetAdditionalMeal = ( timeID, comment )=>{
    //придётся каждый раз перепроверять, существует ли такой коммент, и если да - перезписывать, если нет, писать новый
    //храним массив комментов тут. а контекст перезаписываем только после получения ответа с сервера
    let newAdditionalMealsComments = [];
    let isNewComment = true;
    additionalMealsComments.map((item, index)=>{
      let renewAdditionalMealsComment = item;
      if ( timeID === item.timeID ){
        renewAdditionalMealsComment.comment = comment;
        isNewComment = false;
      }
      newAdditionalMealsComments = [... newAdditionalMealsComments, renewAdditionalMealsComment];
    });
    if (isNewComment){
      let newCommentObj = {
        today: context.updateDay,
        room: props.selectedRoom.number,
        timeID: timeID,
        comment: comment,
      };
      newAdditionalMealsComments = [... newAdditionalMealsComments, newCommentObj];
    }
    setAdditionalMealsComments(newAdditionalMealsComments);
    setEdited(1);
  };

  const onSaveAdditionalMealsComments=()=>{
    axi('barAdditionalMealsComments.php', 'save', { 
      token: context.token, 
      data: additionalMealsComments
    }).then((result) => {
      onSave();
    }, (e) => { 
    });
    //отлось написать этот бекенд. и добавить в бекенд чтение этой тамлицы в общее чтение
  };

  const handlePressCancelButton = ()=>{
    if (typeof props.onSetRoom === 'function'){
      props.onSetRoom(null);
      let cleenArr = [];
      setOrders(cleenArr);
      if (typeof props.onSetOrders === 'function'){
        props.onSetOrders(cleenArr);
      }
      if (typeof props.onSetCurrentAdditionalMealsComments === 'function'){
        props.onSetCurrentAdditionalMealsComments(cleenArr);
      }
    }
  };

  const reassemblyOrders = () => {
    
    console.log(props.selectedRoom);
    if(context.orders.length > 0){
      let localOrders = context.orders.filter((orderItem)=> orderItem.roomName == props.selectedRoom.number);
      console.log(localOrders);
      setOrders(localOrders);
      if (typeof props.onSetOrders === 'function'){
        props.onSetOrders(localOrders);
      }

      let newCurrentAdditionalMealsComments = context.additionalMealsComments.filter((commentObjItem)=>{
        return (commentObjItem.room == props.selectedRoom.number);
      });
      if (typeof props.onSetCurrentAdditionalMealsComments === 'function'){
        props.onSetCurrentAdditionalMealsComments(newCurrentAdditionalMealsComments);
      }
      
      setIsWaiting(false);
    }
  };

  //useEffect(reassemblyOrders,[context.orders]);//тестировать и следить чтобы из за этого ничего не слетало

  const handlePressMakeDefaultButton = async() => {
    setIsWaiting(true);
    let newOrder = {
      selectedRoom: props.selectedRoom, 
      today: context.updateDay,
      currentWeekDay: moment(context.updateDay, 'll').isoWeekday(),
      isOtherDay: moment(context.updateDay, 'll').isoWeekday() !== moment().isoWeekday(),
      nowH: nowH,
      nowM: nowM,
      nowMinutes: nowMinutes,
      token: context.token,
    };

    let file = 'addOrdersByDefaultWorkpieces.php';

    //переаписать с промиса на трай ассинк
    axi(file, '', newOrder).then(async (result) => {
      if (result.type === 'empty'){
        alert('нет заготовок по умолчанию на выбранный Вами период времени');
        setIsWaiting(false);
      }else{
        if (result.type === 'approved') {        
          if (typeof context.axiUpdate === 'function'){
            let upd = await context.axiUpdate();
            //setTimeout(reassemblyOrders, 3000); 
            setIsWaiting(false);
            handlePressCancelButton();//временно, пока не решим проблему
          }else{
            alert('заполнение заказами по умолчанию выполнено успешно, перезагрузите страницу');
          } 
        } else {
          alert('сбой авторизации, войдите снова');
          setIsWaiting(false);
        }
      }
    }, (e) => {
      alert(e);
      setIsWaiting(false);
    });
    
    setEdited(0);
  };

  useEffect(()=>{
    calcCalorics();
  },[orders]);

  return (
    <PopUp
      style={{
        backgroundColor: (context.theme==='light')?'#fff':'#000',
      }}
    > 
      {props.selectedRoom?.contraindications&&
        <TopBar
          style={{
            backgroundColor: (context.theme==='light')?'#effd':'#333d',
          }}>
          <Paragraph theme={context.theme}>противопоказания:</Paragraph>
          <Paragraph theme={context.theme}>{props.selectedRoom?.contraindications}</Paragraph>
        </TopBar>
      }
      
      <Container
        style={{
          backgroundColor: (context.theme==='light')?'#fff':'#000',
          marginTop: props.selectedRoom?.contraindications ? 166 : 16,
        }}
      >
        {props.orders?.length === 0 &&
          <Button 
            style={{margin: 4}}
            variant="contained"
            onClick={handlePressMakeDefaultButton}>
                заполнить дежурными блюдами
          </Button>
        }
        <Button 
          style={{margin: 4}}
          color="secondary"
          variant="contained"
          onClick={handlePressCancelButton}>
              отмена
        </Button>
        {context.mealTimes.map((itemTime, indexTime)=> {
          let itemTimeComment = '';
          additionalMealsComments.map((itemCommentObj, indexCommentobj)=>{
            if ( itemCommentObj.timeID === itemTime.id){
              itemTimeComment = itemCommentObj.comment;
            }
          });
          return (
            <MealTimeItem
              key={'time'+indexTime}
              itemTime = {itemTime}
              indexTime = {indexTime}
              //today = {context.updateDay}
              orders = {orders}
              handleOnPressMenuItem = {handleOnPressMenuItem}
              selectedRoom = {props.selectedRoom}
              nowMinutes = {nowMinutes}
              additionalMeal = {itemTimeComment}
              onSetAdditionalMeal = {handleOnSetAdditionalMeal}
              isWaiting = {isWaiting}

            />
          );
        }
        )}
        <div
          style={{
            display: 'flex',
            flexDirection:'column',
            backgroundColor: (context.theme==='light')?'#ccc':'#333',
            borderRadius: 8,
            padding: 8,
            margin: 8
          }}
        >
          <b
            style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>за весь день</b>
          <div
            style={{
              ...localStyles.calcResult,
              width: 300,
              backgroundColor: (Math.abs(props.selectedRoom.diet.proteins-proteins))<10?'#99cc3399':(Math.abs(props.selectedRoom.diet.proteins-proteins))>500?'#dd563399':'transparent'
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Белки:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{proteins+' из '+props.selectedRoom.diet.proteins+' грамм'}</span>
          </div>
          <div
            style={{
              ...localStyles.calcResult,
              width: 300,
              backgroundColor: (Math.abs(props.selectedRoom.diet.fats-fats))<10?'#99cc3399':(Math.abs(props.selectedRoom.diet.fats-fats))>500?'#dd563399':'transparent'
                            
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Жиры:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{fats+' из '+props.selectedRoom.diet.fats+' грамм'}</span>
          </div>
          <div
            style={{
              ...localStyles.calcResult,
              width: 300,
              backgroundColor: (Math.abs(props.selectedRoom.diet.carbs-carbs))<10?'#99cc3399':(Math.abs(props.selectedRoom.diet.carbs-carbs))>500?'#dd563399':'transparent'
                            
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Углеводы:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{carbs+' из '+props.selectedRoom.diet.carbs+' грамм'}</span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection:'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 32,
              width: 300,
              backgroundColor: (Math.abs(props.selectedRoom.diet.caloric-calorics))<50?'#99cc3399':(Math.abs(props.selectedRoom.diet.caloric-calorics))>1000?'#dd563399':'transparent'
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Калорийность:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{calorics+' из '+props.selectedRoom.diet.caloric+' кКал'}</span>
          </div>
        </div>
        {(edited!==0)&&
          <Button 
            style={{margin: 4}}
            color="primary"
            variant="contained"
            onClick={onSaveAdditionalMealsComments}>
              сохранить
          </Button>
        }
          
        <Button 
          style={{margin: 4, marginBottom: 80}}
          color="secondary"
          variant="contained"
          onClick={handlePressCancelButton}>
              отмена
        </Button>
      </Container>
    </PopUp>
     
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  }
  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;

const PopUp = styled.div`
  ${'' /* display: flex; */}
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 200;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 220;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const localStyles = {
  calcResult: {
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    width: 200,    
    borderBottomColor: '#404040',
    borderWidth: 1,
    borderBottomStyle: 'dotted',
  }
};