import React, { Component, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axi from '../../../../functions/axiosf';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Flip from 'react-reveal/Flip';

import {Context} from '../../../../functions/context';
import EntityButtonGroup from './EntityButtonGroup';
import weekdays from '../../../../constants/weekdays';

//moment().format('dddd');

export default function MealsForm(props) {
  const classes = useStyles();
  const [name, setName] = useState(props.item?.name);
  
  const [article, setArticle] = useState(props.item?.article);
  //const [daysInWeek, setDaysInWeek] = useState(props.item?.daysInWeek)

  const [time, setTime] = useState([...props.item.time]);
  const [weekday, setWeekday] = useState([...props.item.weekday]);
  const [type, setType] = useState(props.item?.type);
  const [diet, setDiet] = useState([...props.item.diet]);

  
  const [proteins, setProteins] = useState(props.item?.proteins);
  const [fats, setFats] = useState(props.item?.fats);
  const [carbs, setCarbs] = useState(props.item?.carbs);
  const [caloric, setCaloric] = useState(props.item?.caloric);

  const [edited, setEdited] = useState(false);

  const context = useContext(Context);

  const onSave = () =>{
    //в метод пробразсываем параметр props.type

    let prot = (proteins)?proteins:0;
    let fat = (fats)?fats:0;
    let carb = (carbs)?carbs:0;
    let calo = (caloric)?caloric:0;

    setEdited(false);
    props.onSave({item: props.item,
      article: article, 
      name: name, 
      diet: diet, 
      type: type, 
      time: time,
      weekday: weekday,
      proteins: prot,
      fats: fat,
      carbs: carb,
      caloric: calo});
  };

  const onDelete = () => {
    props.onDelete(props.item);
  };

  useEffect(()=>{
    if (props.type === 'new'){
      setArticle(props.item.article);
      setName('');
      let newStateArray = [];
      setTime(newStateArray);
      setType('');
      setDiet(newStateArray);
      setWeekday(newStateArray);
      setProteins(0);
      setFats(0);
      setCarbs(0);
      setCaloric(0);
    }
  },[context.meals.length]);

  //console.log(props);
  return (
    
    <Container
      style={{backgroundColor: (context.theme==='light') ? '#dde9':'#4459',
        borderColor: edited? '#d1d':'#778'}}
    >
      <div className={classes.root}>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >{props.title}</p>
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setArticle(e.target.value);
            setEdited(true);
          }}
          value = {article}
          type="text"
          id="outlined-helperText"
          label={'артикль из СБИС'}
          helperText={props.helperText}
          variant="outlined"
        />

        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setName(e.target.value);
            setEdited(true);
          }}
          value = {name}
          type="text"
          id="outlined-helperText"
          label={'наименование блюда'}
          helperText={props.helperText}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>,
          }}
        /><p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >день недели</p>
        {weekdays &&
          <Flip bottom>
            <EntityButtonGroup
              groupArray={weekdays}
              selectorType="weekdays"
              entity={weekday}
              onSetEntity={setWeekday}
              onSetEdited={setEdited}
              {...props}
            />
          </Flip>
        }

        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>

        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >диета</p>
        {context.diets&&
          <Flip bottom>
            <EntityButtonGroup
              groupArray={context.diets}
              selectorType="diets"
              entity={diet}
              onSetEntity={setDiet}
              onSetEdited={setEdited}
              {...props}
            />
          </Flip>
        }

        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >время подачи</p>

        {context.mealTimes&&
          <Flip bottom>
            <EntityButtonGroup
              groupArray={context.mealTimes}
              selectorType="mealTimes"
              entity={time}
              onSetEntity={setTime}
              onSetEdited={setEdited}
              {...props}
            />        
          </Flip>
        }
        <Divider 
          className={classes.margin16}
          style={{marginTop: 0}}/>
        <p
          style={{
            color: (context.theme==='light') ? '#222':'#eef',
            textAlign: 'center',
            padding: 5}}
        >категория блюда</p>

        {context.mealsTypes&&
          <Flip bottom>
            <ButtonGroup 
              className={classes.margin16} 
              size="large" color="primary" 
              orientation="vertical"
              aria-label="vertical large primary button group">
              {context.mealsTypes.map((item, index)=>{
                return(
                  <Button 
                    key={'type'+index}
                    className={classes.button}
                    variant={(type===item.id)?'contained':'outlined'}
                    onClick={()=>{
                      setType(item.id);
                      setEdited(true);
                    }}
                  >{item.name}</Button>
                );
              })}
            </ButtonGroup>   
          </Flip>
        }


          
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setProteins(e.target.value);
            setEdited(true);
          }}
          value = {proteins}
          type="number"
          id="outlined-helperText"
          label={'белки'}
          helperText={props.helperText}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'грамм'}</InputAdornment>,
          }}
        /><TextField
          className={classes.margin16}
          onChange={(e)=>{
            setFats(e.target.value);
            setEdited(true);
          }}
          value = {fats}
          type="number"
          id="outlined-helperText"
          label={'жиры'}
          helperText={props.helperText}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'грамм'}</InputAdornment>,
          }}
        /><TextField
          className={classes.margin16}
          onChange={(e)=>{
            setCarbs(e.target.value);
            setEdited(true);
          }}
          value = {carbs}
          type="number"
          id="outlined-helperText"
          label={'углеводы'}
          helperText={props.helperText}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'грамм'}</InputAdornment>,
          }}
        /> 
        <TextField
          className={classes.margin16}
          onChange={(e)=>{
            setCaloric(e.target.value);
            setEdited(true);
          }}
          value = {caloric}
          type="number"
          id="outlined-helperText"
          label={'энергетическая ценность'}
          helperText={props.helperText}
          variant="outlined"
          //autoFocus={props.autoFocus}
          focused={props.focused}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'кКал'}</InputAdornment>,
          }}
        />
        {props.type==='new'&&edited&&name&&time&&type&&diet&&article&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                onSave();
                setEdited(false);     
              }}>
              добавить
            </Button>
        }
        {props.type==='edit'&&edited&&article&&
            <Button 
              color="primary"
              variant="contained"
              onClick={()=>{
                onSave();
                setEdited(false);
              }}>
              изменить
            </Button>
        }
        {props.type==='edit'&&article&&
            <Button 
              color="secondary"
              variant="contained"
              onClick={()=>onDelete()}>
              удалить
            </Button>
        }

      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s'
  },
  button: {
    transition: '1s'
  }
  
}));
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 5px;
  overflow: hidden;
  transition: '1s';
  border: 2px solid #778;
  border-radius: 8px;
`;