import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import {lay} from '../../../../constants/Layout';
import Paragraph from '../../../../styledComponents/Paragraph';
import {Context} from '../../../../functions/context';

const pageWidth = 740;

export default function PrintTableTimeItem(props) {
  const context = useContext(Context);
  return(
    <Page>
      <ItemRow
        key={props.room}
        style={{
          backgroundColor: (context.theme==='light')?'#eee':'#333',
        }}
      >
        <h2
          style={{
            color: (context.theme==='light') ? '#224':'#bbf',
            margin: 8,
          }}>
          {props.room}
        </h2>
        {context.rooms.map((item, index)=>
          item.contraindications && item.number===props.room ?            
            <Paragraph
              key={index}
              theme={context.theme}
            >
              противопоказания: {item.contraindications}
            </Paragraph>
            : 
            null
        )}
        <RowWrap>
          {context.cookRoomsPrint 
        && context.cookRoomsPrint[props.room] 
        && Object.keys(context.cookRoomsPrint[props.room]).map((keyTime, indTime)=>{
          let additionalMeals = [];
          let keyTimeId = 0;
          context.mealTimes.map((keyTimeItem, keyTimeIndex)=>{
            if (keyTimeItem.name === keyTime){
              keyTimeId = keyTimeItem.id;
            }
          });
          context.additionalMealsComments.map((commentObjItem, commentObjIndex)=>{
            if (commentObjItem.timeID === keyTimeId && commentObjItem.room === props.room){
              additionalMeals = [...additionalMeals, commentObjItem];
            }
          });
          return(
            <Column
              key={'time'+indTime}
            >
              <b
                style={{
                  color: (context.theme==='light') ? '#224':'#bbf',
                }}>
                {keyTime}
              </b>
              {Object.keys(context.cookRoomsPrint[props.room][keyTime]).map((keyType, indType)=>{
                return(
                  <div key={'type'+indType}>
                    {/* <p
                    style={{
                      color: (context.theme==='light') ? '#222':'#eef',
                      padding: 4
                    }}>
                    {keyType}
                  </p> */}
                    {context.cookRoomsPrint[props.room][keyTime][keyType].map((itemMeal, indexMeal)=> {  
                      return (
                        <Cell
                          key={'meal'+indexMeal}
                        >
                          <p
                            style={{
                              color: context.mealsNumb[itemMeal] ? ((context.theme==='light') ? '#222':'#eef') : ((context.theme==='light') ? '#822':'#f99') ,     
                              padding: 2
                            }}>
                            {context.mealsNumb[itemMeal] ? context.mealsNumb[itemMeal]?.name : `ошибка посика блюда № ${itemMeal}`}
                          </p>
                        </Cell>
                      );
                    })}
                  </div>
                );
              })}
              {additionalMeals &&
                additionalMeals.map((itemMeal, indexMeal)=> {  
                  return (
                    <Cell
                      key={'comment'+indexMeal}
                    >
                      <p
                        style={{
                          color: (context.theme==='light') ? '#222':'#eef',     
                          backgroundColor: (context.theme==='light')?'#5ff':'#044',
                          padding: 2
                        }}>
                        {itemMeal?.comment}
                      </p>
                    </Cell>
                  );
                })
              }
            </Column>
          );
        })}
        </RowWrap>
      </ItemRow>
    </Page>
  );
   
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 120px;
  margin-top: 2px;
  font-size: 12px;
  border-width: 1px; 
  border-style: groove;
`;

const ItemRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${pageWidth}px;
  margin-top: 8px;
  font-size: 12px;
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
`;

const Page = styled.div`
  display: block;
  position: relative;
  width: ${pageWidth+8}px;
  page-break-inside: avoid;
`;

const RowWrap = styled.div`
  display: flex;
  flex: 5;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  min-width: ${pageWidth}px;
  margin: 8px;
  font-size: 12px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 120px;
  font-size: 10px;
`;
