import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainAdmin from './MainAdmin';
import MainDiet from './MainDiet';
import MainBarmaid from './MainBarmaid/index';
import MainСook from './MainСook/MainСook';
import Meals from '../Admin/Meals';


function Main(props) {

  switch(props.userRole) {
    case 'администратор':  
      return (
        <Meals/>  
      );
    case 'диетолог':  
      return (
        <MainDiet/>  
      );
    case 'буфетчица':  
      return (
        <MainBarmaid/>  
      );
    case 'повар':  
      return (
        <MainСook/>  
      );
    default:  
      return(<></>);
  }
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 300,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  }
  
}));


export default Main;